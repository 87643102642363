require('./bootstrap');

import Swal from 'sweetalert2'

window.activateStudent = function(studentId, studentName) {
    Swal.fire({
        icon: 'warning',
        title: 'Confirm',
        text: 'Please confirm you want to activate an account for ' + studentName,
        showCancelButton: true,
        confirmButtonText: 'Yes, activate',
        confirmButtonColor: '#009CD5',
        cancelButtonText: 'Cancel',
        cancelButtonColor: '#333'
    }).then((result) => {
        if (result.isConfirmed) {
            document.getElementById('student-activate-' + studentId).submit();
        }
    });
}
